import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';



function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" mt={1}>
      {'Made by '}
      <Link href="https://ricolearning.com/" target="_blank">RicoLearning.com&nbsp;</Link>
    </Typography>
  );
}

export default function Footer() {
  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 4, sm: 8 },
        py: { xs: 8, sm: 5 },
        textAlign: { sm: 'center', md: 'left' },
      }}
    >

      <Box

      >
        <div>
          <Copyright />
        </div>
      </Box>
    </Container>
  );
}
