import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import InfiniteScroll from 'react-infinite-scroll-component';
import CircularProgress from '@mui/material/CircularProgress';

const sortByDateDesc = (newsArray) => {
  return newsArray.sort((a, b) => new Date(b.shortDate) - new Date(a.shortDate));
};

export default function Features({ newsData }) {
  const [visibleItems, setVisibleItems] = React.useState(8); 
  const [hasMore, setHasMore] = React.useState(true); 

  let parsedNewsData = [];

  if (Array.isArray(newsData)) {
    console.log("newsData ARRAY:"+newsData);
    parsedNewsData = newsData;
  } else {
    console.error('Unexpected data', newsData);
  }

  console.log('parsedNewsData', parsedNewsData);

  const sortedNewsData = sortByDateDesc(parsedNewsData);

  const loadMoreItems = React.useCallback(() => {
    if (visibleItems >= sortedNewsData.length) {
      setHasMore(false);
      return;
    }
    setVisibleItems(prev => prev + 3);
  }, [visibleItems, sortedNewsData.length]);

  const displayedItems = sortedNewsData.slice(0, visibleItems);

  return (
    <Box 
      sx={{ 
        background: 'linear-gradient(to bottom, lightblue, white)',
        pt: { xs: 10, sm: 0 }
      }}
    >
      <Container 
        id="features" 
        sx={{ 
          py: { xs: 8, sm: 16 }, 
          display: 'flex', 
          justifyContent: 'center',
        }}
      >
        <Grid container spacing={6} justifyContent="center">
          <Grid item xs={12}>
            <InfiniteScroll
              dataLength={displayedItems.length}
              next={loadMoreItems}
              hasMore={hasMore}
              loader={
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70px' }}>
                  <CircularProgress />
                </Box>
              }
              endMessage={
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50px' }}>
                  <Typography variant="h5" color="text.secondary">
                    All caught up!
                  </Typography>
                </Box>
              }
              style={{ paddingBottom: '60px' }}
            >
              <Grid container spacing={3} direction="column" alignItems="center">
                {displayedItems.map((news, index) => (
                  <Grid item xs={12} key={index}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Card
                        variant="outlined"
                        sx={{
                          width: '100%', 
                          maxWidth: 700, 
                          height: { xs: 200, sm: 135 },
                          cursor: 'pointer',
                          overflow: 'hidden', 
                          transition: 'transform 0.15s, box-shadow 0.15s, background-color 0.15s', 
                          '&:hover': {
                            transform: 'scale(1.00)', 
                            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', 
                            backgroundColor: 'rgba(0, 0, 255, 0.05)', 
                          },
                        }}
                        onClick={() => window.open(news.link || '#', '_blank')}
                      >
                        <Box p={1.5}>
                          <Typography variant="h6" color="text.primary" sx={{ fontSize: '1rem' }}>
                            {news.title || 'Title not available'}
                          </Typography>
                          <Typography variant="body2" color="text.secondary" sx={{ my: 0.5, fontSize: '0.875rem' }}>
                            {news.shortSummary || 'Summary not available'}
                          </Typography>
                          <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5, fontSize: '0.75rem' }}>
                            {news.shortDate || 'Date not available'}
                          </Typography>
                        </Box>
                      </Card>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </InfiniteScroll>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
