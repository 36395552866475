import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';

const items = [
  {
    icon: <CurrencyBitcoinIcon />,
    title: 'Bitcoin',
    description:
      'Lightning Address: ',
    value:
      'ricolearning@getalby.com'
  },
  {
    icon: <AccountBalanceWalletIcon />,
    title: 'Ethereum',
    description:
      'Ethereum Address: ',
    value:
      '0xE5Bd11f500B46D3fa846865ba6a072bE4c9a2Db5'
  }
];

export default function Highlights() {
  return (
    <Box
      id="highlights"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: 'white',
        bgcolor: '#06090a',
      }}
    >
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', md: '60%' },
            textAlign: 'center'
          }}
        >
          <Typography component="h2" variant="h4">
            Buy us a beer!🍻
          </Typography>
          <Typography variant="body1" sx={{ color: 'grey.400' }}>
          Explore the RicoLearning Suite, offering a variety of tools crafted to support educators. All applications are available at no cost, with additional projects in development.
          </Typography>
        </Box>
        <Grid container spacing={2.5}>
          {items.map((item, index) => (
            <Grid item xs={12} key={index}>
              <Stack
                direction="row"
                color="inherit"
                component={Card}
                spacing={2}
                sx={{
                  p: 3,
                  border: '1px solid',
                  borderColor: 'grey.800',
                  background: 'transparent',
                  backgroundColor: 'grey.900',
                  alignItems: 'center'
                }}
              >
                <Box sx={{ opacity: '50%' }}>{item.icon}</Box>
                <div>
                  <Typography variant="body2" sx={{ color: 'grey.400' }}>
                    {item.description}
                  </Typography>
		  <Typography fontWeight="bold" sx={{
            	    color: 'grey.300',
                    pt: { xs: 1, sm: 2 },
                    fontSize: { xs: '0.64rem', sm: '1rem' }, // Adjust font size for mobile (xs) and desktop (sm)
              	  }}>
                    {item.value}
                 </Typography>
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
