import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const logoStyle = {
  width: '30px',
  height: 'auto',
  cursor: 'pointer',
};

function AppAppBar() {
  return (
    <AppBar position="fixed" sx={{ boxShadow: 0, bgcolor: 'transparent', backgroundImage: 'none', mt: 2 }}>
      <Toolbar sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '999px',
            bgcolor: 'rgba(255, 255, 255, 0.4)',
            backdropFilter: 'blur(24px)',
            p: 2,
            boxShadow: `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`,
          }}
        >
          <img src='/static/apple-60.png' style={logoStyle} alt="Rico News Logo" />
          <Typography variant="h6" sx={{ fontSize: '30px', color: 'black', ml: 1 }}>
            Rico
            <Typography component="span" variant="h6" sx={{ fontSize: '30px', color: (theme) => theme.palette.primary.main, ml: 0 }}>
              News
            </Typography>
          </Typography>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default AppAppBar;
