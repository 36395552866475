import * as React from 'react';
import { useState, useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import AppAppBar from './components/AppAppBar';
import Highlights from './components/Highlights';
import Features from './components/Features';
import Footer from './components/Footer';
import getLPTheme from './getLPTheme';

import axios from 'axios';


export default function App() {
  const [showCustomTheme] = React.useState(true);
  const [newsData, setNewsData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/getNews");
        console.log("response from axios" + response.data);
        setNewsData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  const LPtheme = createTheme(getLPTheme('light'));
  const defaultTheme = createTheme({ palette: { mode: 'light' } });

  return (
    <ThemeProvider theme={showCustomTheme ? LPtheme : defaultTheme}>
      <CssBaseline />
      <AppAppBar />
      <Box sx={{ background: 'linear-gradient(to bottom, #e6f2fc, #ffffff)' }}>
        <Features newsData={newsData} />
        <Divider />
        <Highlights />
        <Divider />
        <Footer />
      </Box>
    </ThemeProvider>
  );
}
